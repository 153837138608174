import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import wdtLoading from 'wdt-loading'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import {
  AuthTitle,
  Button,
  Container,
  CopySubText,
  ErrorBanner,
  MiscText,
  MobileAuthContainer,
} from '.'
import WorkspaceSetupSidebar from '../../components/workspaceSetupSidebar'
import Check from '../../icons/check'
import axios from 'axios'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { isMobileView } from '../../utils/isMobileView'
import MobileTopBar from '../../components/mobileTopBar'

const CompleteContainer = () => (
  <AuthProvider>
    <Complete />
  </AuthProvider>
)

const FormBox = styled.div`
  /* margin-top: 234px; */
  width: 100%;
  display: flex;
  justify-content: center;
`

const Header = styled.div`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

const ProgressPill = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 47px;
  /* background-color: rgba(246, 246, 246, 0.5); */
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-family: ${props => props.theme.typography.fontFamilyGT}; */
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`

const ProgressBackground = styled.div`
  position: absolute;
  background: #f6f6f6;
  top: 32px;
  width: 100%;
  border-radius: 100px;
  height: 47px;
  z-index: -2;
`

const Progress = styled.div`
  position: absolute;
  background: linear-gradient(156.21deg, #b0ed6a -3.25%, #88fcd3 92.16%);
  top: 32px;
  width: 5%;
  height: 47px;
  z-index: -1;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  ${props =>
    props.percent > 5 && props.percent < 95
      ? css`
          width: ${props.percent}%;
        `
      : props.percent === 100
        ? css`
          width: 100%;
          border-bottom-right-radius: 100px;
          border-top-right-radius: 100px;
        `
        : css`
          width: ${props.percent < 5 ? '5' : '95'}%;
        `};
`

const FlexColumnBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const LoadingContainer = styled.div`
  .wdt-loading-screen {
    /* width: 100%; */
    height: 109px;
    background-color: #fff;
    /* position: fixed; */
    /* z-index: 999998; */
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
  }

  .wdt-loading-phrases {
    color: rgb(0, 0, 0);
    width: 429px;
    margin: 0 auto;
    /* margin-top: 100%; */
    height: 106px;
    overflow: hidden;
    position: relative;
    /* padding: 10px 0; */
    /* text-align: center; */
    top: 50%;
    transform: translateY(-50%);
  }

  .wdt-loading-phrases:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    @media (max-width: 769px) {
      top: -1px;
      height: 110%;
    }
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(255, 255, 255, 1)),
      color-stop(50%, rgba(255, 255, 255, 0)),
      color-stop(100%, rgba(255, 255, 255, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
  }

  .wdt-loading-phrase {
    padding-left: 59px;
    position: relative;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    transform: translateY(30px);
    line-height: 30px;
    height: 30px;
    font-size: 14px;
  }

  .wdt-loading-phrase:before {
    content: ' ';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 6px;
    left: 5px;
    background: #000000;
    /* border: 1px solid rgba(0, 0, 0); */
    border-radius: 50%;
    /* box-shadow: inset 0 0 0 2px white; */
  }

  .wdt-loading-phrase:after {
    opacity: 0;
    height: 6px;
    width: 5.5px;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    border-right: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    border-radius: 0 !important;
    content: '';
    left: 8px;
    top: 15px;
    position: absolute;
    -webkit-transform: scaleX(-1) rotate(135deg);
    -moz-transform: scaleX(-1) rotate(135deg);
    -ms-transform: scaleX(-1) rotate(135deg);
    -o-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
  }

  .wdt-loading-phrase.wdt-checked:after {
    -webkit-animation-delay: 100ms;
    -moz-animation-delay: 100ms;
    animation-delay: 100ms;
    -webkit-animation-duration: 200ms;
    -moz-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-name: checkmark;
    -moz-animation-name: checkmark;
    animation-name: checkmark;
    -webkit-transform: scaleX(-1) rotate(135deg);
    -moz-transform: scaleX(-1) rotate(135deg);
    -ms-transform: scaleX(-1) rotate(135deg);
    -o-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @-webkit-keyframes checkmark {
    0% {
      height: 0;
      width: 7px;
      opacity: 1;
    }
    20% {
      height: 2px;
      width: 7px;
      opacity: 1;
    }
    40% {
      height: 5px;
      width: 7px;
      opacity: 1;
    }
    100% {
      height: 11px;
      width: 7px;
      opacity: 1;
    }
  }

  @-moz-keyframes checkmark {
    0% {
      height: 0;
      width: 7px;
      opacity: 1;
    }
    20% {
      height: 2px;
      width: 7px;
      opacity: 1;
    }
    40% {
      height: 5px;
      width: 7px;
      opacity: 1;
    }
    100% {
      height: 11px;
      width: 7px;
      opacity: 1;
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 7px;
      opacity: 1;
    }
    20% {
      height: 2px;
      width: 7px;
      opacity: 1;
    }
    40% {
      height: 5px;
      width: 7px;
      opacity: 1;
    }
    100% {
      height: 11px;
      width: 7px;
      opacity: 1;
    }
  }
`

const PageTopLoader = styled.div`
  /* border: 2px solid; */
  height: 2px;
  background: linear-gradient(
    270deg,
    #000000 -0.19%,
    rgba(70, 71, 78, 0) 99.91%
  );
  left: 0px;
  top: 0px;
  position: fixed;
  transition: all 200ms;
  /* width: calc(100vw - ${props => props.progress || 1}); */
`

const Complete = () => {
  const { userSession, token } = useContext(AuthContext)
  const [progress, setProgress] = useState(0)
  const [magicLinkUrl, setMagicLinkUrl] = useState('')
  const [error, setError] = useState(null)
  const [loaderRef, isLoaderContainerVisible] = useInView({})
  useEffect(() => {
    checkUtmParams()
  }, [])

  useEffect(() => {
    if (isLoaderContainerVisible) {
      wdtLoading.start()
    }
  }, [isLoaderContainerVisible])

  useEffect(() => {
    // fake loader
    let intervalId = null
    if (progress < 99) {
      intervalId = setInterval(() => {
        const newProgress =
          progress < 80
            ? progress + Math.floor(Math.random() * 5)
            : progress + 0.2
        setProgress(newProgress)
      }, 1000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [progress, setProgress])

  useEffect(() => {
    let intervalId = null
    if (token) {
      intervalId = setInterval(() => {
        axios
          .get(
            `${config.backendUri}/auth/sign-up/tenant/provisioning-progress`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(result => {
            setError(null)
            if (parseInt(result.data.progress, 10) > progress) {
              if (result.data.progress >= 100) {
                // Generate the magic link and then set the progress to 100.
                axios
                  .post(
                    `${config.backendUri}/auth/session/magic-links`,
                    {
                      tenantId: result.data.tenantId,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(mlResult => {
                    setMagicLinkUrl(mlResult.data.magicLink)
                    setProgress(100)
                    console.log(mlResult.data.magicLink)
                    window.location.href = mlResult.data.magicLink
                  })
                  .catch(mlError => {
                    setError(handleAuthNetworkError(mlError))
                  })
              } else {
                setProgress(parseInt(result.data.progress, 10))
              }
            }
            if (parseInt(result.data.progress, 10) === 100) {
              clearInterval(intervalId)
            }
          })
          .catch(progressError => {
            setError(handleAuthNetworkError(progressError))
          })
      }, 1000)
    }

    return () => clearInterval(intervalId)
  }, [token, progress, setProgress, setError, magicLinkUrl, setMagicLinkUrl])

  useHotjarScript()

  if (!userSession) return null

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Complete" />
        <MobileTopBar>Complete</MobileTopBar>
        <div className="auth-mobile-body">
          <Box>
            {progress === 100 ? (
              <>
                <AuthTitle>You're in! ✨</AuthTitle>
                <CopySubText>
                  You will be redirected to your{' '}
                  <a href={magicLinkUrl}>new workspace</a> in a few seconds...
                </CopySubText>
              </>
            ) : (
              <>
                <AuthTitle>You&apos;re almost there...</AuthTitle>
                <CopySubText>
                  Give us a few seconds to prep your workspace – soon you’ll be
                  able to get in there and start pulsing.
                </CopySubText>
                <motion.div
                  initial={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 5,
                  }}
                  animate={{
                    opacity: 0,
                  }}
                  style={{
                    width: '429px',
                    height: '109px',
                    backgroundColor: '#FFFFFF',
                    marginBottom: '-109px',
                    zIndex: 9999,
                    position: 'fixed',
                  }}
                ></motion.div>
                <LoadingContainer ref={loaderRef}>
                  <div className="wdt-loading-screen">
                    <div className="wdt-loading-phrases">
                      <div
                        className="wdt-loading-phrase-category"
                        data-category="default"
                        style={{ transition: 'all 2s' }}
                      >
                        <div className="wdt-loading-phrase">
                          Warming up servers...
                        </div>
                        <div className="wdt-loading-phrase">
                          Creating workspace...
                        </div>
                        <div className="wdt-loading-phrase">
                          Creating user accounts...
                        </div>
                        <div className="wdt-loading-phrase">
                          Feeding unicorns...
                        </div>
                        <div className="wdt-loading-phrase">
                          Creating streams...
                        </div>
                        <div className="wdt-loading-phrase">
                          Building content templates...
                        </div>
                        <div className="wdt-loading-phrase">
                          Coffee break...
                        </div>
                        <div className="wdt-loading-phrase">
                          Initializing browser extensions...
                        </div>
                        <div className="wdt-loading-phrase">
                          Hugging servers...
                        </div>
                        <div className="wdt-loading-phrase">
                          Reserving storage space...
                        </div>
                        <div className="wdt-loading-phrase">
                          Peeling avocados...
                        </div>
                        <div className="wdt-loading-phrase">
                          Waking up the video transcriber...
                        </div>
                        <div className="wdt-loading-phrase">
                          Setting up your profile...
                        </div>
                        <div className="wdt-loading-phrase">
                          Cooking pizzas...
                        </div>
                        <div className="wdt-loading-phrase">
                          Just a few more seconds...
                        </div>
                      </div>

                      <div
                        className="wdt-loading-phrase-category"
                        data-category="profile"
                      >
                        <div className="wdt-loading-phrase">
                          Getting avatar...
                        </div>
                        <div className="wdt-loading-phrase">
                          Collecting project memberships...
                        </div>
                        <div className="wdt-loading-phrase">
                          Getting dom together...
                        </div>
                        <div className="wdt-loading-phrase">
                          Getting followers...
                        </div>
                        <div className="wdt-loading-phrase">
                          Calculating best skills...
                        </div>
                        <div className="wdt-loading-phrase">
                          Getting feed...
                        </div>
                        <div className="wdt-loading-phrase">
                          Writing title...
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingContainer>
              </>
            )}
            {error && (
              <Box style={{ marginTop: '24px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
          </Box>
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout>
      <SEO title="Complete" />
      <Container>
        {/* <PageTopLoader
          style={{
            width: progress + '%',
          }}
        /> */}
        <motion.div
          style={{
            height: '4px',
            background: `linear-gradient(
              270deg,
              #000000 -0.19%,
              rgba(70, 71, 78, 0) 99.91%
            )`,
            left: '0px',
            top: '0px',
            position: 'fixed',
            borderRadius: '10px',
          }}
          initial={{
            width: '0px',
          }}
          animate={{
            width: progress + '%',
          }}
          transition={{
            duration: 4,
          }}
        />
        {/* <WorkspaceSetupSidebar step={4} subStep={3} remainingTime="3 min" /> */}
        <FormBox>
          <Box style={{ width: '400px' }}>
            {progress === 100 ? (
              <>
                <AuthTitle>You're in! ✨</AuthTitle>
                <CopySubText
                  style={{
                    marginTop: '16px',
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                >
                  You will be redirected to your{' '}
                  <a href={magicLinkUrl}>new workspace</a> in a few seconds...
                </CopySubText>
              </>
            ) : (
              <>
                <AuthTitle>You&apos;re almost there...</AuthTitle>
                <CopySubText
                  style={{
                    marginTop: '16px',
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                >
                  Give us a few seconds to prep your workspace – soon you’ll be
                  able to get in there and start pulsing.
                </CopySubText>
                <motion.div
                  initial={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 5,
                  }}
                  animate={{
                    opacity: 0,
                  }}
                  style={{
                    width: '429px',
                    height: '109px',
                    backgroundColor: '#FFFFFF',
                    marginBottom: '-109px',
                    zIndex: 9999,
                    position: 'fixed',
                  }}
                ></motion.div>
                <LoadingContainer ref={loaderRef}>
                  <div className="wdt-loading-screen">
                    <div className="wdt-loading-phrases">
                      <div
                        className="wdt-loading-phrase-category"
                        data-category="default"
                        style={{ transition: 'all 2s' }}
                      >
                        <div className="wdt-loading-phrase">
                          Warming up servers...
                        </div>
                        <div className="wdt-loading-phrase">
                          Creating workspace...
                        </div>
                        <div className="wdt-loading-phrase">
                          Creating user accounts...
                        </div>
                        <div className="wdt-loading-phrase">
                          Feeding unicorns...
                        </div>
                        <div className="wdt-loading-phrase">
                          Creating streams...
                        </div>
                        <div className="wdt-loading-phrase">
                          Building content templates...
                        </div>
                        <div className="wdt-loading-phrase">
                          Coffee break...
                        </div>
                        <div className="wdt-loading-phrase">
                          Initializing browser extensions...
                        </div>
                        <div className="wdt-loading-phrase">
                          Hugging servers...
                        </div>
                        <div className="wdt-loading-phrase">
                          Reserving storage space...
                        </div>
                        <div className="wdt-loading-phrase">
                          Peeling avocados...
                        </div>
                        <div className="wdt-loading-phrase">
                          Waking up the video transcriber...
                        </div>
                        <div className="wdt-loading-phrase">
                          Setting up your profile...
                        </div>
                        <div className="wdt-loading-phrase">
                          Cooking pizzas...
                        </div>
                        <div className="wdt-loading-phrase">
                          Just a few more seconds...
                        </div>
                      </div>

                      <div
                        className="wdt-loading-phrase-category"
                        data-category="profile"
                      >
                        <div className="wdt-loading-phrase">
                          Getting avatar...
                        </div>
                        <div className="wdt-loading-phrase">
                          Collecting project memberships...
                        </div>
                        <div className="wdt-loading-phrase">
                          Getting dom together...
                        </div>
                        <div className="wdt-loading-phrase">
                          Getting followers...
                        </div>
                        <div className="wdt-loading-phrase">
                          Calculating best skills...
                        </div>
                        <div className="wdt-loading-phrase">
                          Getting feed...
                        </div>
                        <div className="wdt-loading-phrase">
                          Writing title...
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingContainer>
                {/* <Box style={{ display: 'flex', position: 'relative' }}>
                  <ProgressBackground />
                  <Progress percent={progress} />
                  <ProgressPill>
                    <Box style={{ display: 'flex' }}>
                      <FlexColumnBox>
                        <Check />
                      </FlexColumnBox>
                      <FlexColumnBox style={{ marginLeft: '4px' }}>
                        {userSession.workspaceSubdomain + '.pulse.so'}
                      </FlexColumnBox>
                      <MiscText style={{ fontSize: '12px', marginLeft: '4px' }}>
                        [{'' + Math.floor(progress)}%]
                      </MiscText>
                    </Box>
                  </ProgressPill>
                </Box>
                <Box style={{ marginTop: '16px' }}>
                  <Button disabled={progress < 100}>Get In There</Button>
                </Box> */}
              </>
            )}
            {error && (
              <Box style={{ marginTop: '16px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
          </Box>
        </FormBox>
      </Container>
    </Layout>
  )
}

export default CompleteContainer
